<template>
  <router-view/>
  <div :class="$style.footer">&copy; 2022 Bodyhacker's Cloud | <a href="https://tom.st/impressum">Legal Notice</a> | <a href="https://tom.st/datenschutz">Privacy</a></div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 1rem;
}
</style>

<style module>
.footer{
  text-align: center;
  font-size: 0.8rem;
  line-height: 2rem;
}
</style>
